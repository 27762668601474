#menu,
.menu_shadow,
.menu_wrapper {
  transition: all 0.5s;
}

#menu {
  .menu_shadow {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    position: fixed;
    @include rtl-sass-prop(left, right, 0);
    top: 0;
    z-index: 100;
  }

  .menu_wrapper {
    width: 230px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 200;
    background-color: $tertiary-purple;
    box-shadow: 0 -9px 35px 0 rgba(0, 0, 0, 0.13);
    box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.3);

    .menu_logo,
    .menu_logo_small {
      height: 100px;
      text-align: center;
    }

    .menu_logo {
      .logoImg {
        background: url($logo2);
        padding: 37px 161px 0 0;
        background-size: 161px 37px;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-top: calc((64px - (50px * 0.6)) / 2);
      }

      .product {
        margin: 15px 35px 0;
        color: $primary-green;
        font-size: 16px;
        font-family: 'GTWalsheimPro-Medium';
        text-transform: uppercase;
        text-align: left;
      }
    }

    .menu_logo_small .logoImg {
      background: url(~@/assets/uploads/menu_icon_home.png);
      padding: calc(32px * 0.7) calc(42px * 0.7) 0 0;
      background-size: calc(42px * 0.7) calc(32px * 0.7);
      background-repeat: no-repeat;
      cursor: pointer;
      margin-top: calc((64px - calc(32px * 0.7)) / 2);
    }

    .el-scrollbar {
      .nav-group__title {
        @include rtl-sass-prop(padding-left, padding-right, 35px);
        font-size: 16px;
        line-height: 1.92;
        color: $primary-green;
        text-transform: uppercase;
        line-height: 3em;
        padding: 1em 0;
      }
    }

    .swith_to {
      border-radius: $button-radius;
      background-color: $primary-purple;
      line-height: 1;
      text-align: center;
      color: $white;
      padding: 17px 23.5px 16px 24.5px;
      display: block;
      max-width: 183px;
      margin: 20px auto;
    }
  }
}

.el-menu-item span,
.el-submenu span {
  display: inline-block;
  width: 140px;
  overflow-x: hidden;
  white-space: initial;
  overflow-wrap: break-word;
  line-height: 20px;
  color: white;
}

/deep/ .el-menu-item,
/deep/ .el-submenu__title {
  background-color: $quarternary-purple !important;
}

.divider {
  &:not(:nth-last-child(-n + 1)) {
    border-bottom: 0.1px solid hsla(0, 0%, 100%, 0.1);
  }
}

/deep/ .el-menu-item.is-active {
  background-color: $primary-purple !important;

  .line {
    display: block;
  }
}

/deep/.el-menu-vertical-demo .el-submenu.is-opened .el-submenu__title,
/deep/ .el-menu-vertical-demo .el-submenu.is-opened .el-menu-item {
  background-color: $secondary-purple !important;
}

/deep/ .el-menu-vertical-demo .el-submenu.is-opened .el-menu-item.is-active {
  background: $primary-purple !important;
}

/deep/ .el-menu-vertical {
  margin-bottom: 70px;

  .line {
    width: 8px;
    height: 100%;
    position: absolute;
    @include rtl-sass-prop(left, right, 0);
    top: 0;
    display: none;
    background: $primary-purple;
  }
}

.icon {
  width: 20px;
  @include rtl-sass-value(margin, 0 10px 5px 15px, 0 15px 5px 10px);
}

.el-menu-item .icon_small,
.el-submenu .icon_small {
  margin-left: 0;
}

.el-menu-vertical .line,
.el-menu-item.is-active .line {
  @include rtl-sass-prop(left, right, 0);
}

/deep/ .el-scrollbar__view > ul {
  background-color: $tertiary-purple !important;
  border-right-width: 0px;
}
