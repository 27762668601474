.wrapper {
  height: 100vh;
  overflow: hidden;

  .wrapper_main {
    width: 100%;
    padding: 0 20px;
    overflow: hidden;
    min-height: calc(100vh - 61px - 96px);

    .wrapper_content {
      width: 100%;
      max-width: 1440px;
      padding-top: 64px;
      padding-bottom: 60px;
      margin: 0 auto;

      .content_title {
        width: fit-content;
        height: 50px;
        margin-bottom: 60px;

        h2 {
          font-size: 32px;
          color: $primary-purple;
          font-family: 'GTWalsheimPro-Bold';
          line-height: 1.38;
        }

        p {
          height: 5px;
          background: $primary-green;
        }
      }
    }
  }
}

.fade-enter {
  opacity: 0.9;
}

.fade-leave {
  opacity: 0.9;
}

.fade-enter-active {
  transition: opacity 2s;
}

.fade-leave-active {
  transition: opacity 1s;
}

.client-portal-loading {
  display: inline-block;
  width: 150px;
  height: 153px;
  background: url($loading) no-repeat center;
  background-size: cover;
  background-size: cover;
  animation: rotate 2s 1 linear;
  -moz-animation: rotate 2s infinite linear;
  -ms-animation: rotate 2s infinite linear;
  -webkit-animation: rotate 2s infinite linear;
  -o-animation: rotate 2s infinite linear;
}

@media (max-width: 768px) {
  .wrapper .wrapper_main .wrapper_content {
    padding-top: 48px;

    .content_title h2 {
      font-size: 24px;
    }
  }
}
