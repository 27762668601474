@font-face {
  font-family: 'GTWalsheimPro';
  src: url('GTWalsheimPro-Regular.eot');
  src: local('GT Walsheim Pro Regular'), local('GTWalsheimPro-Regular'),
    url('GTWalsheimPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('GTWalsheimPro-Regular.woff2') format('woff2'), url('GTWalsheimPro-Regular.woff') format('woff'),
    url('GTWalsheimPro-Regular.ttf') format('truetype'), url('GTWalsheimPro-Regular.svg') format('svg');
  /* font weight 400 */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro-Bold';
  src: url('GTWalsheimPro-Bold.eot');
  src: local('GT Walsheim Pro Bold'), local('GTWalsheimPro-Bold'),
    url('GTWalsheimPro-Bold.eot?#iefix') format('embedded-opentype'), url('GTWalsheimPro-Bold.woff2') format('woff2'),
    url('GTWalsheimPro-Thin.woff') format('woff'), url('GTWalsheimPro-Bold.ttf') format('truetype'),
    url('GTWalsheimPro-Thin.svg') format('svg');
  /* font weight 600 */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro-Thin';
  src: url('GTWalsheimPro-Thin.eot');
  src: local('GT Walsheim Pro Thin'), local('GTWalsheimPro-Thin'),
    url('GTWalsheimPro-Thin.eot?#iefix') format('embedded-opentype'), url('GTWalsheimPro-Thin.woff2') format('woff2'),
    url('GTWalsheimPro-Thin.woff') format('woff'), url('GTWalsheimPro-Thin.ttf') format('truetype'),
    url('GTWalsheimPro-Thin.svg') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro-UltraLight';
  src: url('GTWalsheimPro-UltraLight.eot');
  src: local('GT Walsheim Pro Ultra Light'), local('GTWalsheimPro-UltraLight'),
    url('GTWalsheimPro-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('GTWalsheimPro-UltraLight.woff2') format('woff2'), url('GTWalsheimPro-UltraLight.woff') format('woff'),
    url('GTWalsheimPro-UltraLight.ttf') format('truetype'), url('GTWalsheimPro-UltraLight.svg') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro-Light';
  src: url('GTWalsheimPro-Light.eot');
  src: local('GT Walsheim Pro Light'), local('GTWalsheimPro-Light'),
    url('GTWalsheimPro-Light.eot?#iefix') format('embedded-opentype'), url('GTWalsheimPro-Light.woff2') format('woff2'),
    url('GTWalsheimPro-Light.woff') format('woff'), url('GTWalsheimPro-Light.ttf') format('truetype'),
    url('GTWalsheimPro-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheimPro-Medium';
  src: url('GTWalsheimPro-Medium.eot');
  src: local('GT Walsheim Pro Medium'), local('GTWalsheimPro-Medium'),
    url('GTWalsheimPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('GTWalsheimPro-Medium.woff2') format('woff2'), url('GTWalsheimPro-Medium.woff') format('woff'),
    url('GTWalsheimPro-Medium.ttf') format('truetype'), url('GTWalsheimPro-Medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}
