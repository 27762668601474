.notice,
.noticeDisable,
.notification_wrapper {
  display: inline-block;
}

.notification_wrapper {
  .notice_mobile {
    display: none;

    img {
      width: 25px;
    }
  }

  .noticeDisable {
    cursor: pointer;
    @include rtl-sass-prop(margin-left, margin-right, 20px);

    img {
      width: 25px;
      @include rtl-sass-prop(padding-right, padding-left, 5px);
    }
  }

  .notice {
    overflow: hidden;
    width: 500px;
    vertical-align: middle;

    .notification {
      border: none;
      white-space: nowrap;
      color: $primary-purple;
      @include rtl-sass-value(
        animation,
        scroll-right-left 20s linear 0.01s infinite,
        scroll-left-right 20s linear 0.01s infinite
      );

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@keyframes scroll-right-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: -100%;
  }
}

@keyframes scroll-left-right {
  from {
    margin-right: 100%;
  }
  to {
    margin-right: -100%;
  }
}

@media (max-width: 812px) {
  .notice,
  .noticeDisable,
  .notification_wrapper {
    margin-bottom: -5px;
  }

  .notification_wrapper {
    .notice_mobile {
      display: inline-block;

      .notification {
        border: none;
        @include rtl-sass-prop(padding-left, padding-right, 15px);
        @include rtl-sass-prop(margin-right, margin-left, 10px);
        font-size: 14px;
        .el-icon-warning {
          font-size: 1.25rem;
        }
      }
    }

    .noticeDisable {
      @include rtl-sass-prop(margin-left, margin-right, 10px);
      margin-bottom: 4px;
    }

    .notice {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .notification_wrapper {
    .notice_mobile {
      display: inline-block;
      .notification {
        .el-icon-warning {
          font-size: 0.95rem;
        }
      }
    }
  }
}
