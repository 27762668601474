footer {
  padding: 15px;
  max-width: 1440px;
  margin: 0 auto;

  .inner {
    margin-top: 30px;

    .divider {
      height: 2px;
      background: black;
    }

    p {
      margin: 30px 0;
      padding: 0px 40px;
      line-height: 1.64;
      color: black;
      text-align: justify;
    }
  }
}

@media (max-width: 768px) {
  footer .inner p {
    padding: 0 20px;
  }
}

@media (max-width: 500px) {
  footer .inner p {
    padding: 0;
  }
}
