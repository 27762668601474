header {
  width: 100%;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  position: sticky;
  z-index: 99;
  top: 0;

  > ul {
    margin: 0 auto;
    max-width: 100%;

    .fl {
      margin-top: 3px;

      .header_btn {
        width: 24px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }
    }

    .fr {
      padding-right: 12px;
      .areaImg {
        width: 25px;
        cursor: pointer;
      }
      .areaName {
        cursor: pointer;
        padding-left: 10px;
        color: $primary-purple;
        @include rtl-sass-prop(margin-right, margin-left, 24px);
      }

      /deep/ .el-dropdown {
        cursor: pointer;
      }

      .user {
        .member_logo {
          @include rtl-sass-prop(margin-right, margin-left, 10px);
          width: 25px;
        }

        .down {
          width: 16px;
          margin-top: -5px;
          @include rtl-sass-prop(margin-left, margin-right, 8px);
        }

        .userName {
          vertical-align: text-top;
          display: inline-block;
          height: 19px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          .name {
            line-height: normal;
            text-align: right;
            color: $primary-purple;
            vertical-align: text-bottom;
          }
        }
      }
    }
  }
}

.login_dropdown_box {
  min-width: 182px;

  .el-dropdown-menu__item {
    padding: 15px 18px;

    .icon {
      width: 24px;
      height: 24px;
      @include rtl-sass-prop(margin-right, margin-left, 10px);
      margin-bottom: 1px;
      display: inline-block;
      vertical-align: bottom;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
    }

    &.login_back .icon {
      background-image: url(~@/assets/uploads/header/login_back_icon.png);
    }

    &.logout .icon {
      background-image: url(~@/assets/uploads/header/logout_icon.png);
    }
  }
}

.lang_dropdown_box .el-dropdown-menu__item {
  padding: 12px 24px;

  img {
    width: 24px;
    @include rtl-sass-prop(margin-right, margin-left, 15px);
  }
}

.login_dropdown_box,
.lang_dropdown_box {
  .el-dropdown-menu__item {
    line-height: normal;
    position: relative;

    //文字預設顏色
    span {
      height: 24px;
      line-height: 1.71;
      color: $primary-purple;
    }
  }
}

@media (max-width: 768px) {
  header {
    padding: 24px;
  }
}

@media (max-width: 500px) {
  header {
    > ul {
      .fl {
        margin-top: 0;
      }

      .fr {
        padding-top: 2px;
        @include rtl-sass-prop(padding-right, padding-left, 0);

        .member_logo {
          @include rtl-sass-prop(margin-right, margin-left, 8px);
        }

        .userName {
          width: 90px;
        }
      }
    }
  }
}

@media (max-width: 340px) {
  header > ul > {
    .fr {
      .user {
        margin-top: 10px;
      }
      .member_logo {
        display: none;
      }
    }
  }
}
